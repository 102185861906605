<script setup>
import ScrollDownIcon from 'assets/images/chevron.svg'

const props = defineProps({
	blok: {
		type: Object,
		required: true
	}
})

const {
	videoPortrait,
	videoLandscape,
	image,
	blurhash,
	eyeCatcher: [eyeCatcher]
} = props.blok

const videoSources = ref([
	{
		key: 'portrait',
		src: videoPortrait
	},
	{
		key: 'landscape',
		src: videoLandscape
	}
])

const heroElement = ref(null)
const scrollDown = () => {
	const nextElement = heroElement.value.nextElementSibling
	nextElement.scrollIntoView({
		behavior: 'smooth',
		block: 'start'
	})
}
</script>

<template>
	<section
		ref="heroElement"
		v-editable="blok"
		class="hero gradient-overlay"
	>
		<ImageElement
			v-if="image?.filename"
			class="hero-image"
			:src="image.filename"
			:alt="image.alt"
			:blurhash="blurhash"
			:focal-point="image?.focus"
			:preload="true"
		/>
		<video
			v-for="({ key, src }) in videoSources"
			:key="key"
			:src="src"
			:class="`hero-video ${key}`"
			autoplay
			loop
			muted
			playsinline
		/>
		<EyeCatcher
			v-if="eyeCatcher"
			:key="eyeCatcher._uid"
			:data="eyeCatcher"
		/>
		<ScrollDownIcon
			class="scroll-down"
			@click="scrollDown"
		/>
	</section>
</template>

<style scoped>
.hero {
	--gradient-start: 0;
	--gradient-opacity-start: 50%;
	--gradient-end: 50%;
	--gradient-opacity-end: 0%;

	position: relative;

	overflow: hidden;
	display: grid;
	grid-column: full;
	justify-items: center;

	min-height: 100vh;

	color: var(--color-white);

	background-color: var(--color-black);

	@supports (min-height: 100svh) {
		min-height: 100svh;
	}

	> * {
		position: relative;
		z-index: 1;
	}

	@media (--medium) {
		align-content: end;
	}
}

.hero-video,
.hero-image {
	pointer-events: none;

	position: absolute !important;
	z-index: 0;
	inset: 0;

	aspect-ratio: auto;
	width: 100%;
	height: 100%;
	margin: auto;

	&::before {
		content: "";

		position: absolute;
		z-index: 2;
		inset: 0;

		background-color: hsl(0deg 0% 0% / 20%);
	}

	:deep(img) {
		object-position: 30% 40%;
	}
}

.hero-video {
	object-fit: cover;
	object-position: 60% 80%;

	&.portrait {
		display: block;

		@media (--medium) {
			display: none;
		}
	}

	&.landscape {
		display: none;

		@media (--medium) {
			display: block;
		}
	}

	@media (--medium) {
		object-position: 70% 80%;
	}
}

@keyframes scroll-down {
	0% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(0.5rem);
	}

	100% {
		transform: translateY(0);
	}
}

.scroll-down {
	cursor: pointer;

	z-index: 2;

	overflow: visible;
	grid-column: 1 / -1;
	align-self: end;

	height: 2rem;
	padding: var(--space);

	animation: scroll-down 4s 2s var(--ease-in-out-quart) infinite alternate;

	& :deep(path) {
		transform-origin: 50% 100%;
		transform-style: preserve-3d;
		transform: scale(var(--scale, 1)) rotateX(var(--rotate, 0deg));

		stroke-width: 2px;

		transition: transform 500ms ease-in-out;
	}

	&:hover,
	&:focus {
		--scale: 1.2;
		--rotate: 10deg;

		animation-play-state: paused;

		& :deep(path) {
			transition-duration: 300ms;
		}
	}

	&:active {
		--scale: 0.9;
		--rotate: 30deg;

		& :deep(path) {
			transition-duration: 300ms;
		}
	}
}
</style>
