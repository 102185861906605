<script setup>
import { animate, spring } from 'motion'

const props = defineProps({
	data: {
		type: Object,
		required: true
	}
})

const {
	title,
	text,
	link,
	eventName
} = toRefs(props.data)

const isLink = computed(() => !!link.value?.cached_url)
const NuxtLink = resolveComponent('NuxtLink')

const eyeCatcher = ref(null)
const hover = ref(false)

const {
	x: cursorX,
	y: cursorY
} = useMouse({ type: 'client' })

const {
	left,
	top,
	width,
	height
} = useElementBounding(eyeCatcher)

const offsetHoverEnter = 0.9
const offsetHoverLeave = 0.2
const hoverArea = ref(offsetHoverEnter)

const calculateHoverEffect = () => {
	if (!eyeCatcher.value) {
		return
	}

	const eyeCatcherElement = unrefElement(eyeCatcher)

	const elementCenter = {
		x: left.value + width.value / 2,
		y: top.value + height.value / 2
	}

	const distX = cursorX.value - elementCenter.x
	const distY = cursorY.value - elementCenter.y
	const dist = Math.sqrt(distX ** 2 + distY ** 2)

	if (dist < width.value * hoverArea.value) {
		hover.value = true
		animate(eyeCatcherElement,
			{ transform: `translate(${distX * 0.8}px, ${distY * 0.8}px)` },
			{ duration: 1, easing: spring() }
		)
	} else if (hover.value) {
		onMouseLeave()
	}
}

const onMouseEnter = () => {
	hover.value = true
	hoverArea.value = offsetHoverEnter
}

const onMouseLeave = () => {
	if (!eyeCatcher.value) {
		return
	}

	const eyeCatcherElement = unrefElement(eyeCatcher)

	hover.value = false
	hoverArea.value = offsetHoverLeave

	animate(eyeCatcherElement, {
		transform: 'translate(0, 0)'
	}, {
		duration: 0.8,
		easing: spring()
	})
}

const {
	pause,
	resume
} = useRafFn(calculateHoverEffect, { immediate: false })

watch(hover, (isHovering) => isHovering ? resume() : pause())

onUnmounted(pause)

const { trackEvent } = useFathom()
</script>

<template>
	<Component
		:is="isLink ? NuxtLink : 'div'"
		ref="eyeCatcher"
		v-editable="data"
		:to="link?.cached_url"
		:target="link?.target"
		class="eye-catcher"
		@mouseenter="onMouseEnter"
		@mouseleave="onMouseLeave"
		@click="trackEvent(eventName)"
	>
		<div class="bubble">
			<h2
				v-if="title"
				class="title"
				v-html="title"
			/>
			<p
				class="text"
				v-html="text"
			/>
		</div>
	</Component>
</template>

<style scoped>
.eye-catcher {
	--size: 30ch;
	--padding: var(--space);

	user-select: none;
	align-self: end;
	justify-self: end;
	margin: var(--padding);

	&:hover {
		--scale: 1.05;
		--brightness: 1.1;
	}

	&[href]:active {
		--scale: 0.95;
		--brightness: 0.5;
		--contrast: 1.5;
	}

	@media (--extra-large) {
		margin-inline-end: calc(var(--padding) * 5);
	}
}

.bubble {
	transform: scale(var(--scale, 1)) rotate(-12deg);

	display: flex;
	flex-flow: column nowrap;
	gap: 0.5rem;
	place-content: center;
	place-items: center;

	aspect-ratio: 1;
	max-width: var(--size);
	padding: 2rem;

	color: var(--color-white);
	text-align: center;

	background-color: var(--color-blue);
	filter: brightness(var(--brightness, 1)) contrast(var(--contrast, 1));
	border-radius: 50%;

	transition-duration: var(--transition-duration);

	.title {
		max-width: 75%;
		font-size: var(--font-size-medium);
		line-height: 1.2;
	}

	.text {
		font-size: var(--font-size-small);
	}
}
</style>
